<template>
    <page-title
        title="備品一覧"
        icon="bi-card-checklist"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <section class="section" v-else>
        <table class="table table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="col-md-8">名称</th>
                    <th class="col-md-4 text-center">所持数量</th>
                    <th class="col-md-4 text-center">単位</th>
                    <th class="col-md-4 text-center">並び順</th>
                    <th class="col-md-4"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="equipment, index in equipments" :key="equipment">
                    <td class="">
                        {{ equipment.equipment_name }}
                    </td>
                    <td class="text-center">
                        {{ equipment.quantity }}
                    </td>
                    <td class="text-center">
                        {{ equipment.unit }}
                    </td>
                    <td class="text-center">
                        {{ equipment.priority }}
                    </td>
                    <td class="text-center">
                        <button-exec
                            text="編集..."
                            icon="bi-box-arrow-up-left"
                            @click="openModal(equipment, index)"
                        ></button-exec>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="ms-2">
            <button-exec
                text="新規登録..."
                icon="bi-plus-lg"
                @click="openModal()"
            ></button-exec>
        </div>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    {{ modal_title }}
                                </h4>
                                <button type="button" class="btn-close" @click="cancelEdit"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="save" id="modal_form">
                                    <div class="row mb-5">
                                        <div class="form-group col-md-6">
                                            <label>名称</label>
                                            <form-input
                                                v-model="modal_equipment.equipment_name"
                                                :required="true"
                                            ></form-input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>単位</label>
                                            <form-input
                                                v-model="modal_equipment.unit"
                                                :required="true"
                                            ></form-input>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>所持数量</label>
                                            <form-input-number
                                                v-model="modal_equipment.quantity"
                                                :required="true"
                                                min="0"
                                                step="1"
                                            ></form-input-number>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>並び順</label>
                                            <form-input-number
                                                v-model="modal_equipment.priority"
                                                :required="true"
                                                min="0"
                                                max="9999"
                                                step="1"
                                            ></form-input-number>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button-exec-create
                                    v-if="!modal_equipment.equipment_id"
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-create>
                                <button-exec-update
                                    v-else
                                    size="btn-lg"
                                    form="modal_form"
                                ></button-exec-update>
                                <button-exec-delete
                                    v-if="modal_equipment.equipment_id"
                                    size="btn-lg"
                                    @click="$refs.confirm_remove.show()"
                                ></button-exec-delete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove" @ok="remove()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import InlineLoader from '@/components/tools/InlineLoader';
import Equipment from '@/models/entities/equipment';
import FormInput from '@/components/forms/FormInput.vue';
import FormInputNumber from '@/components/forms/FormInputNumber.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate.vue';


export default {
    name: 'Equipment',
    components: {
        PageTitle,
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormInputNumber,
        ButtonExec,
        ButtonExecDelete,
        ButtonExecCreate,
        ButtonExecUpdate,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            equipments: [],
            modal: false,
            modal_equipment: new Equipment(),
            original_equipment: new Equipment(),
            modal_index: null,
            modal_title: null,
        }
    },
    mounted() {
        this.fetchEquipmentList();
    },
    methods: {
        fetchEquipmentList() {
            this.loading++;
            this.$http.get(`/equipments`)
            .then(response => {
                for (let row of response.data) {
                    this.equipments.push(new Equipment(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        openModal(equipment = null, index = null) {
            this.modal = true;
            this.modal_index = index;
            if (equipment) {
                this.modal_title = '備品編集';
                this.modal_equipment = equipment;
                // オブジェクトを値渡しに変える処理
                this.original_equipment = JSON.stringify(equipment);
                this.original_equipment = JSON.parse(this.original_equipment);

            } else {
                this.modal_title = '備品登録';
                this.modal_equipment = new Equipment();
                this.modal_equipment.priority = 9999;
            }

        },
        cancelEdit() {
            if (this.modal_equipment.equipment_id) {
                let target_index = this.equipments.findIndex(equipment => equipment.equipment_id === this.original_equipment.equipment_id);

                this.equipments[target_index].equipment_name = this.original_equipment.equipment_name;
                this.equipments[target_index].unit = this.original_equipment.unit;
                this.equipments[target_index].quantity = this.original_equipment.quantity;
                this.equipments[target_index].priority = this.original_equipment.priority;

                this.original_equipment = new Equipment();
            }
            this.closeModal();
        },
        closeModal() {
            this.modal = false;
        },
        save() {
            if (this.modal_equipment.equipment_id) {
                this.startScreenLoading();
                this.$http.put(`/equipments/${this.modal_equipment.equipment_id}`, this.modal_equipment)
                .then((response) => {
                    let updated_equipment = new Equipment(response.data);

                    // 削除して追加
                    let deleteIndex = this.equipments.findIndex(equipment => equipment.equipment_id === updated_equipment.equipment_id);
                    this.equipments.splice(deleteIndex, 1);
                    this.pushAndSortByPriority(updated_equipment);

                    this.showMessage('編集しました');
                    this.modal_equipment = new Equipment();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            } else {
                this.startScreenLoading();
                this.$http.post(`/equipments`, this.modal_equipment)
                .then(response => {
                    let created_equipment = new Equipment(response.data);
                    this.pushAndSortByPriority(created_equipment);

                    this.showMessage('登録しました');
                    this.modal_equipment = new Equipment();
                })
                .finally(() => {
                    this.endScreenLoading();
                    this.closeModal();
                });
            }
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/equipments/${this.modal_equipment.equipment_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.equipments.splice(this.modal_index, 1);
            })
            .finally(() => {
                this.endScreenLoading();
                this.closeModal();
            });
        },
        pushAndSortByPriority(saved_equipment) {
            let priority = saved_equipment.priority;
            let index = this.equipments.findIndex(equipment => equipment.priority > priority);

            if (index < 0) {
                this.equipments.push(saved_equipment);
            } else {
                this.equipments.splice(index, 0, saved_equipment);
            }
        }
    }
}
</script>

<style scoped>

</style>
