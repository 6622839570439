import { isNud } from '@/utilities/typing';

/**
 * 社用車マスタ エンティティ
 */
class Equipment {
    equipment_id;
    equipment_name;
    unit;
    quantity;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.equipment_id = properties.equipment_id;
        this.equipment_name = properties.equipment_name;
        this.unit = properties.unit;
        this.quantity = properties.quantity;
        this.priority = properties.priority;
    }
}

export default Equipment;
